#hero-no-slider {
    width: 100%;
    height: 60vh;
    overflow: hidden;
    position: relative;
    text-align: center;
  }
  #hero-no-slider::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgb(209, 209, 209), rgba(255, 255, 255, 0)), url("../../public/assets/img/hero-bgimg.jpg") center top no-repeat;
  }
  #hero-no-slider h2 {
    color: #000000;
    margin-bottom: 15px;
    font-size: 48px;
    font-weight: 700;
  }
  #hero-no-slider p {
    color: #000000;
  }
  #hero-no-slider .btn-get-started {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #000000;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    border: 2px solid #000000;
  }
  #hero-no-slider .btn-get-started:hover {
    background: #76ba1b;
    color: #fff;
    text-decoration: none;
  }
  @media (max-width: 575px) {
    #hero-no-slider h2 {
      font-size: 30px;
    }
  }




/*--------------------------------------------------------------
  # Our Services
  --------------------------------------------------------------*/
  .services {
    padding-bottom: 20px;
  }
  .services .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    margin: 0 0 40px 0;
    background: #fff;
    box-shadow: 0 5px 26px 0 rgba(68, 88, 144, 0.14);
    transition: all 0.3s ease-in-out;
    text-align: center;
    border: 1px solid #fff;
    color: #111;
    }
  .services .icon {
    margin: 0 auto 20px auto;
    padding-top: 17px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    width: 72px;
    height: 72px;
  }
  .services .icon i {
    font-size: 36px;
    line-height: 1;
  }
  .services .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  .services .title a {
    color: #111;
  }
  .services .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
  }
  .services .icon-box-pink .icon {
    background: #fceef3;
  }
  .services .icon-box-pink .icon i {
    color: #ff689b;
  }
  .services .icon-box-pink:hover {
    border-color: #ff689b;
  }
  .services .icon-box-cyan .icon {
    background: #e6fdfc;
  }
  .services .icon-box-cyan .icon i {
    color: #3fcdc7;
  }
  .services .icon-box-cyan:hover {
    border-color: #3fcdc7;
  }
  .services .icon-box-green .icon {
    background: #eafde7;
  }
  .services .icon-box-green .icon i {
    color: #41cf2e;
  }
  .services .icon-box-green:hover {
    border-color: #41cf2e;
  }
  .services .icon-box-blue .icon {
    background: #e1eeff;
  }
  .services .icon-box-blue .icon i {
    color: #2282ff;
  }
  .services .icon-box-blue:hover {
    border-color: #2282ff;
  }
.services .icon-bx {
  color:#111;
}
  /*--------------------------------------------------------------
  # Service Details
  --------------------------------------------------------------*/
  .service-details {
    padding-bottom: 10px;
  }
  .service-details .card {
    border: 0;
    padding: 0 30px;
    margin-bottom: 60px;
    position: relative;
  }
  .service-details .card-img {
    width: calc(100% + 60px);
    margin-left: -30px;
    overflow: hidden;
    z-index: 9;
    border-radius: 0;
  }
  .service-details .card-img img {
    max-width: 100%;
    transition: all 0.3s ease-in-out;
  }
  .service-details .card-body {
    z-index: 10;
    background: #fff;
    border-top: 4px solid #fff;
    padding: 30px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    margin-top: -60px;
    transition: 0.3s;
  }
  .service-details .card-title {
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
  }
  .service-details .card-title a {
    color: #1e4356;
    transition: 0.3s;
  }
  .service-details .card-text {
    color: #5e5e5e;
  }
  .service-details .read-more a {
    color: #777777;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    transition: 0.3s;
  }
  .service-details .read-more a:hover {
    color: #68A4C4;
  }
  .service-details .card:hover img {
    transform: scale(1.1);
  }
  .service-details .card:hover .card-body {
    border-color: #68A4C4;
  }
  .service-details .card:hover .card-body .card-title a {
    color: #68A4C4;
  }

    /*--------------------------------------------------------------
  # Why Us
  --------------------------------------------------------------*/
  .why-us .container {
    box-shadow: 0 5px 25px 0 rgba(214, 215, 216, 0.6);
    background: #fff;
  }
  .why-us .icon-box + .icon-box {
    margin-top: 50px;
  }
  .why-us .icon-box .icon {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    background: #f3f8fa;
    border-radius: 6px;
    transition: 0.5s;
  }
  .why-us .icon-box .icon i {
    color: #76ba1b;
    font-size: 32px;
  }
  .why-us .icon-box:hover .icon {
    background: #76ba1b;
  }
  .why-us .icon-box:hover .icon i {
    color: #fff;
  }
  .why-us .icon-box .title {
    margin-left: 95px;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 18px;
    text-transform: uppercase;
  }
  .why-us .icon-box .title a {
    color: #343a40;
    transition: 0.3s;
  }
  .why-us .icon-box .title a:hover {
    color: #68A4C4;
  }
  .why-us .icon-box .description {
    margin-left: 95px;
    line-height: 24px;
    font-size: 14px;
  }
  .why-us .video-box {
    position: relative;
  }
  .why-us .video-box img {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .why-us .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#76ba1b 50%, rgba(118, 186, 27, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  .why-us .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .why-us .play-btn::before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate-btn 2s;
    animation: pulsate-btn 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(118, 186, 27, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  .why-us .play-btn:hover::after {
    border-left: 15px solid #76ba1b;
    transform: scale(20);
  }
  .why-us .play-btn:hover::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    -webkit-animation: none;
    animation: none;
    border-radius: 0;
  }
  
  @-webkit-keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }