/*--------------------------------------------------------------
  # Features
  --------------------------------------------------------------*/
  .features .row + .row {
    margin-top: 100px;
  }
  .features h3 {
    font-weight: 400;
    font-size: 24px;
  }
  .features ul {
    list-style: none;
    padding: 0;
  }
  .features ul li {
    padding-bottom: 10px;
  }
  .features ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #76ba1b;
  }
  .features p:last-child {
    margin-bottom: 0;
  }