/*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
  .about h3 {
    font-weight: 400;
    font-size: 26px;
  }
  .about ul {
    list-style: none;
    padding: 0;
  }
  .about ul li {
    padding-bottom: 10px;
  }
  .about ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #68A4C4;
  }
  .about p:last-child {
    margin-bottom: 0;
  }