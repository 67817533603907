body {
    font-family: "Open Sans", sans-serif;
    color: #444;
  }
  
  a {
    color: #76ba1b;
    text-decoration: none;
  }
  
  a:hover {
    color: #a4de02;
    text-decoration: none;
  }
  
  h1, h2, h3, h4, h5, h6, .font-primary {
    font-family: "Roboto", sans-serif;
  }
  

  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 60px 0;
  }
  
  .section-bg {
    background-color: #f3f8fa;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  .section-title h2 {
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
  }
  .section-title h2::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }
  .section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #76ba1b;
    bottom: 0;
    left: calc(50% - 20px);
  }
  .section-title p {
    margin-bottom: 0;
  }

  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  .breadcrumbs {
    padding: 15px 0;
    background-color: #f3f8fa;
    min-height: 40px;
  }
  .breadcrumbs h2 {
    font-size: 28px;
    font-weight: 300;
  }
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .breadcrumbs ol li + li {
    padding-left: 10px;
  }
  .breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #6c757d;
    content: "/";
  }
  @media (max-width: 768px) {
    .breadcrumbs .d-flex {
      display: block !important;
    }
    .breadcrumbs ol {
      display: block;
    }
    .breadcrumbs ol li {
      display: inline-block;
    }
  }